import { trim } from 'lodash';
import moment from 'moment';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { capitalize } from '../../../helpers/format';
import Order from '../../../types/entities/Order';
import { useSelector } from '../../../store/hooks';

export default function useOrderCardService(order: Order) {
  const [t] = useTranslation();
  const devises = useSelector((state) => state.app.devises);
  const currentDevise = devises?.find((devise) => order.devise_name[0] === devise.name)?.symbol;

  const orderClient = () =>
    order.client
      ? trim([order.client.last_name.toLocaleUpperCase(), order.client.first_name].join(' ')) || 'Not defined'
      : t('Client_not_indicated');

  const engagementDate = () => {
    const startDate = moment(order.start_date).format('DD/MM/YYYY') || 'Not defined';
    const endDate = moment(order.end_date).format('DD/MM/YYYY') || 'Not defined';
    return [startDate, endDate].join(' - ');
  };

  const finalAmountPrice = () =>
    order.final_amount_price > 0
      ? `${order.final_amount_price} 
  ${currentDevise && currentDevise !== 'E' ? currentDevise.toLowerCase() : '€'}`
      : '-';

  const orderConsultantTooltipTitle = useMemo(
    () =>
      order.consultants ? (
        <ul className="no-bullets" style={{ listStyleType: 'none' }}>
          {order.consultants.map((c) => (
            <li key={c.id}>{`${c.last_name.toLocaleUpperCase()} ${capitalize(c.first_name)}`}</li>
          ))}
        </ul>
      ) : (
        trim([order.client?.last_name.toLocaleUpperCase(), order.client?.first_name].join(' ')) || 'Not defined'
      ),
    [order.consultants, order.client]
  );

  return {
    orderClient,
    engagementDate,
    finalAmountPrice,
    orderConsultantTooltipTitle,
  };
}
