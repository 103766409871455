import { LoadingButton } from '@mui/lab';
import {
  Avatar,
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  InputLabel,
  Radio,
  RadioGroup,
  Tab,
  Tabs,
  TextField,
  Typography,
} from '@mui/material';
import ContentMultiple from 'components/ContentMultiple/ContentMultiple';
import InputDate from 'components/InputDate';
import RateComponent from 'components/Rating/RateComponent';
import Select from 'components/Select';
import SelectUser from 'components/SelectUser';
import useDeliverableService from 'features/Deliverables/hooks/useDeliverableService';
import { capitalize } from 'helpers/format';
import useUserRoles from 'hooks/useUserRoles';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { closeDrawerAction } from 'store/actions/drawerActions';
import { useSelector } from 'store/hooks';
import { useAppState } from 'store/Provider';
import { selectScopes } from 'store/selectors/deliverableSelectors';
import { AppState } from 'store/states/appState';
import { DeliverableState } from 'store/states/deliverableState';
import MissionComment from 'types/entities/MissionComment';
import OrderWorkunit from 'types/entities/OrderWorkunit';
import User from 'types/entities/User';
import { DeliverableTabOption } from 'types/enums/DeliverableTabOption';
import { isActiveFrequency, isActiveStatus } from '../../services/deliverableService';
import DeliverableDetailsCommentCard from './DeliverableDetailsCommentCard/DeliverableDetailsCommentCard';

type DeliverableDetailsProps = {
  id: OrderWorkunit['id'];
  tab: DeliverableTabOption;
};

const DeliverableDetails = (props: DeliverableDetailsProps) => {
  const [t] = useTranslation();
  const { id, tab } = props;
  const { dispatch } = useAppState();
  const appState = useSelector((state) => state.app);
  const userRoles = useUserRoles();
  const state = useSelector((state) => state.deliverable);
  const deliverable = useSelector((state) => state.deliverable.deliverables?.find((d) => d?.id === id) ?? null);
  const scopes = useSelector(selectScopes);
  const { addCommentDeliverable } = useDeliverableService();
  const [currentTab, setCurrentTab] = useState<DeliverableTabOption>(tab ?? DeliverableTabOption.DETAIL);
  const [isCommentLoading, setIsCommentLoading] = useState(false);
  const defaultComment = {
    comment: '',
    is_private: !appState.user?.is_client,
    author: appState.user,
  };
  const [comment, setComment] = useState<{ comment: string; is_private: boolean; author?: User }>(defaultComment);

  // TODO check fonctionnement
  // useEffect(() => {
  //   if (displayComment) {
  //     setCurrentTab(DeliverableTabOption.FEEDBACK);
  //     setIsCommentDisplay(false);
  //   }
  // }, [DeliverableTabOption.FEEDBACK, displayComment, setIsCommentDisplay]);

  const handleSubmit = async (e: { preventDefault: () => void }) => {
    setIsCommentLoading(true);
    if (comment.comment !== '' && deliverable) {
      await addCommentDeliverable(deliverable, comment);
      setComment(defaultComment);
    }
    setIsCommentLoading(false);
  };

  function handleCancel(): void {
    dispatch(closeDrawerAction());
    setComment({ ...comment, comment: '' });
  }
  function renderDrawerPartition() {
    if (currentTab === DeliverableTabOption.FEEDBACK) {
      return (
        <>
          {deliverable && deliverable.rating && (
            <>
              {t('Rating')}
              <RateComponent row={deliverable} setRating={() => Promise.resolve()} disabled />
            </>
          )}
          {deliverable?.['mission-comments'] &&
            deliverable?.['mission-comments'].length > -1 &&
            deliverable?.['mission-comments'].map((comment: MissionComment) => {
              if (userRoles.isClient && comment.is_private) return <></>;
              return (
                <DeliverableDetailsCommentCard
                  id={comment.id}
                  key={comment.id}
                  author={comment.author as User}
                  comment={comment.comment}
                  created_at={comment.created_at}
                  is_private={comment.is_private}
                  mission_advancement_id={comment.mission_advancement_id}
                  mission_id={deliverable?.id}
                  missionComment={comment}
                  mission={deliverable as OrderWorkunit}
                />
              );
            })}
          {!userRoles.isSupportBu && !userRoles.isButl && (
            <form onSubmit={handleSubmit}>
              <Box sx={{ display: 'flex', mb: 3 }}>
                <Box sx={{ mr: 1 }}>
                  {!appState.user?.avatar_uri && (
                    <Avatar>{` ${appState.user?.last_name
                      .toLocaleUpperCase()
                      .charAt(0)}${appState.user?.first_name.charAt(0)}`}</Avatar>
                  )}
                  {appState.user?.avatar_uri && (
                    <Avatar src={appState.user?.avatar_uri} alt={t('Active_user_avatar')} />
                  )}
                </Box>
                <Box sx={{ flex: 1 }}>
                  <TextField
                    name="desc"
                    id="desc"
                    multiline
                    rows={5}
                    onChange={(e) => setComment({ ...comment, comment: e.currentTarget.value })}
                    required
                    fullWidth
                    value={comment.comment}
                    placeholder={t('Leave a comment')}
                    inputProps={{ maxLength: 250 }}
                  />
                  <Typography>{`${comment.comment.length} / 250`}</Typography>
                  {!userRoles.isClient && !userRoles.isConsultant && (
                    <FormGroup>
                      <FormControlLabel
                        control={<Checkbox color="primary" checked={!comment.is_private} />}
                        onChange={(e) => setComment({ ...comment, is_private: !comment.is_private })}
                        label={capitalize(t('Diffuse_external')) as string}
                      />
                    </FormGroup>
                  )}
                </Box>
              </Box>
              <Box sx={{ display: 'flex' }}>
                <Box sx={{ ml: 'auto' }}>
                  <LoadingButton
                    loading={isCommentLoading}
                    onClick={() => handleCancel()}
                    variant="outlined"
                    sx={{ mr: 1 }}
                  >
                    {t('Cancel')}
                  </LoadingButton>
                  <LoadingButton onClick={handleSubmit} loading={isCommentLoading} variant="contained">
                    {t('Send')}
                  </LoadingButton>
                </Box>
              </Box>
            </form>
          )}
        </>
      );
    }

    return (
      <>
        <Box sx={{ mb: 3 }}>
          <InputLabel htmlFor="mission_name">{t('Name')}</InputLabel>
          <TextField
            name="workunit_name"
            id="mission_name"
            className="deliverables-input active-blue"
            placeholder={t('NAME_OF_NEW_DELIVERABLE')}
            disabled
            fullWidth
            defaultValue={deliverable?.workunit_name}
          />
        </Box>
        <Box sx={{ mb: 3 }}>
          <InputLabel htmlFor="mission_ref">{t('Reference')}</InputLabel>
          <TextField
            name="workunit_reference"
            id="mission_ref"
            className="deliverables-input active-blue"
            placeholder={t('REFERENCE_OF_NEW_DELIVERABLE')}
            defaultValue={deliverable?.workunit_reference ?? ''}
            disabled
            fullWidth
          />
        </Box>
        <Box sx={{ mb: 3 }}>
          <InputLabel htmlFor="comment">{t('Description')}</InputLabel>
          <TextField
            name="workunit_desc"
            id="workunit_desc"
            rows={4}
            className="active-blue background-grey no-border deliverables-input "
            disabled
            multiline
            value={deliverable?.workunit?.desc || ''}
            fullWidth
          />
        </Box>
        <Box sx={{ mb: 3 }}>
          <InputLabel htmlFor="content">{t('Content')}</InputLabel>
          <TextField
            name="content"
            id="content"
            rows={4}
            className="active-blue background-grey no-border deliverables-input "
            disabled
            value={deliverable?.content || ''}
            multiline
            fullWidth
          />
        </Box>
        {deliverable && <ContentMultiple orderWorkunit={deliverable} onchange={() => null} />}
        <Box sx={{ mb: 3 }}>
          <InputLabel htmlFor="mission-status" sx={{ mb: 1 }}>
            {t('Status')}
          </InputLabel>
          <RadioGroup
            aria-label="status"
            name="mission-status"
            id="mission-status"
            row
            onChange={(e) => undefined}
            value={deliverable?.['mission-status']?.id ?? deliverable?.mission_status_id}
          >
            {state.status.map((statut) => (
              <FormControlLabel
                key={statut.id}
                value={statut.id}
                control={<Radio color="primary" />}
                label={t<string>(statut.name)}
                className={`wrapped ${isActiveStatus(deliverable as OrderWorkunit, statut)}`}
                disabled
              />
            ))}
          </RadioGroup>
        </Box>
        <Box sx={{ mb: 3 }}>
          <InputLabel htmlFor="mission-frequency" sx={{ mb: 1 }}>
            {t('Frequency')}
          </InputLabel>
          <RadioGroup
            aria-label="frequency"
            name="mission-frequency"
            id="mission-frequency"
            row
            onChange={() => undefined}
            value={deliverable?.['mission-frequency']?.id ?? deliverable?.mission_frequency_id}
          >
            {state.frequencies.map((frequency) => (
              <FormControlLabel
                key={frequency.id}
                value={frequency.id}
                className={`wrapped ${isActiveFrequency(deliverable as OrderWorkunit, frequency)}`}
                control={<Radio color="primary" />}
                label={t<string>(frequency.name)}
                disabled
              />
            ))}
          </RadioGroup>
        </Box>
        <Box sx={{ mb: 3 }}>
          {scopes && (
            <Select
              options={scopes}
              selectedOption={deliverable?.scope}
              optionsLabels="name"
              label={t('Scopes')}
              handleSelectOptions={() => undefined}
              propertyName="scope"
              required={false}
              disabled
            />
          )}
        </Box>
        {appState.consultants && !userRoles.isCustomer && (
          <Box sx={{ mb: 3 }}>
            <SelectUser
              users={appState.consultants}
              selectedUser={userRoles.isConsultant ? appState.user : deliverable?.consultant}
              onSelectUser={() => undefined}
              label={t('Consultant')}
              disabled
            />
          </Box>
        )}{' '}
        {state.customers && (
          <Box sx={{ mb: 3 }}>
            <SelectUser
              users={state.customers}
              selectedUser={deliverable?.client}
              onSelectUser={() => undefined}
              label={t('Client')}
              disabled
            />
          </Box>
        )}{' '}
        {userRoles.isCustomer && (
          <Box sx={{ mb: 3 }}>
            <InputLabel htmlFor="mission-frequency" sx={{ mb: 1 }}>
              {t('Frequency')}
            </InputLabel>
            <RadioGroup
              aria-label="frequency"
              name="mission-frequency"
              id="mission-frequency"
              row
              value={deliverable?.['mission-frequency']?.id ?? deliverable?.mission_frequency_id}
            >
              {state.frequencies.map((frequency) => (
                <FormControlLabel
                  key={frequency.id}
                  value={frequency.id}
                  className={`wrapped `}
                  control={<Radio color="primary" />}
                  label={t(frequency.name) as string}
                  disabled
                />
              ))}
            </RadioGroup>
          </Box>
        )}
        <Box sx={{ mb: 3 }}>
          <InputDate
            label={t('Initial_Deliverable_date')}
            name="delivery_date"
            id="delivery_date"
            placeholder="DD/MM/YYYY"
            onchange={() => null}
            required={false}
            disabled
            value={deliverable?.delivery_date || null}
          />
        </Box>
        <Box sx={{ mb: 3 }}>
          <InputDate
            label={t('New_expected_date')}
            name="forecasted_date"
            id="Forecasted_date"
            placeholder="DD/MM/YYYY"
            onchange={() => null}
            disabled
            value={deliverable?.forecast_date ?? null}
          />
        </Box>
        <Box sx={{ mb: 3 }}>
          <InputLabel htmlFor="workunit_input" sx={{ mb: 1 }}>
            {t('Input')}
          </InputLabel>
          <TextField
            name="workunit_input"
            id="workunit_input"
            rows={4}
            className="active-blue background-grey no-border deliverables-input "
            disabled
            value={deliverable?.workunit_input || ''}
            multiline
            fullWidth
          />
        </Box>
        <Box sx={{ mb: 3 }}>
          <InputLabel htmlFor="workunit_output" sx={{ mb: 1 }}>
            {t('Output')}
          </InputLabel>
          <TextField
            name="workunit_output"
            id="workunit_output"
            rows={4}
            className="active-blue background-grey no-border deliverables-input "
            disabled
            value={deliverable?.workunit_output || ''}
            multiline
            fullWidth
          />
        </Box>
        <Box sx={{ mb: 3 }}>
          <InputLabel htmlFor="workunit_level_standard" sx={{ mb: 1 }}>
            {t('Level_standard')}
          </InputLabel>
          <TextField
            name="workunit_level_standard"
            id="workunit_level_standard"
            rows={4}
            className="active-blue background-grey no-border deliverables-input "
            disabled
            value={deliverable?.workunit_level_standard || ''}
            multiline
            fullWidth
          />
        </Box>
        <Box sx={{ mb: 3 }}>
          <InputLabel htmlFor="workunit_accept_standard" sx={{ mb: 1 }}>
            {t('Accept_standard')}
          </InputLabel>
          <TextField
            name="workunit_accept_standard"
            id="workunit_accept_standard"
            rows={4}
            className="active-blue background-grey no-border deliverables-input "
            disabled
            value={deliverable?.workunit_accept_standard || ''}
            multiline
            fullWidth
          />
        </Box>
        <Box sx={{ mb: 3 }}>
          <InputLabel htmlFor="workunit_comment" sx={{ mb: 1 }}>
            {t('Comment')}
          </InputLabel>
          <TextField
            name="workunit_comment"
            id="workunit_comment"
            rows={4}
            className="active-blue background-grey no-border deliverables-input "
            disabled
            value={deliverable?.workunit_comment || ''}
            multiline
            fullWidth
          />
        </Box>
        {deliverable?.cancellation_reason && deliverable?.label?.includes('cancelled') && (
          <Box sx={{ mb: 3 }}>
            <InputLabel htmlFor="cancellation_reason" sx={{ mb: 1 }}>
              {t('Cancellation reason')}
            </InputLabel>
            <TextField
              name="cancellation_reason"
              id="cancellation_reason"
              rows={4}
              className="active-blue background-grey no-border deliverables-input "
              disabled
              value={deliverable?.cancellation_reason}
              multiline
              fullWidth
            />
          </Box>
        )}
      </>
    );
  }

  return (
    <>
      <Tabs
        value={currentTab}
        indicatorColor="primary"
        textColor="primary"
        aria-label="disabled tabs example"
        onChange={(e, value) => setCurrentTab(value)}
      >
        <Tab label="Details" value={DeliverableTabOption.DETAIL} />
        <Tab label="Feedback" value={DeliverableTabOption.FEEDBACK} />
      </Tabs>
      <Box sx={{ mt: 5 }}>{renderDrawerPartition()}</Box>
    </>
  );
};

export default DeliverableDetails;
