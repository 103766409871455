/* eslint-disable no-nested-ternary */
import { Add } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import MomentUtils from '@mui/lab/AdapterMoment';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { Box, FormControlLabel, Grid, InputLabel, Radio, RadioGroup, TextField } from '@mui/material';
import ContentMultiple from 'components/ContentMultiple/ContentMultiple';
import InputDate from 'components/InputDate';
import AutocompleteCustom, { PropertyNameOptions, SelectTypeOptions } from 'components/Select';
import SelectUser from 'components/SelectUser';
import useDeliverableService from 'features/Deliverables/hooks/useDeliverableService';
import useApi from 'hooks/useApi';
import useUserRoles from 'hooks/useUserRoles';
import moment, { Moment, MomentInput } from 'moment';
import * as momentTz from 'moment-timezone';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { closeDrawerAction } from 'store/actions/drawerActions';
import { useSelector } from 'store/hooks';
import { useAppState } from 'store/Provider';
import { selectScopesForTnM } from 'store/selectors/deliverableSelectors';
import CatalogComplexity from 'types/entities/CatalogComplexity';
import MissionFrequency from 'types/entities/MissionFrequency';
import MissionStatus from 'types/entities/MissionStatus';
import OrderWorkunit from 'types/entities/OrderWorkunit';
import Scope from 'types/entities/Scope';
import User from 'types/entities/User';
import Workunit from 'types/entities/Workunit';
import { DeliverableTabOption } from 'types/enums/DeliverableTabOption';
import { isActiveFrequency } from '../../services/deliverableService';

const TnMDeliverableCreation: React.FC = () => {
  momentTz.tz.setDefault('Europe/Paris');
  const [t] = useTranslation();
  const { dispatch } = useAppState();
  const appState = useSelector((state) => state.app);
  const userRoles = useUserRoles();
  const { createDeliverables } = useDeliverableService();
  const state = useSelector((state) => state.deliverable);
  const order = useSelector((state) => state.deliverable.order);
  const scopes = useSelector(selectScopesForTnM);
  const loadings = useSelector((state) => state.loadings);

  const [isCreateButtonDisabled, setIsCreateButtonDisabled] = useState<boolean>(true);
  const [deliverableToCreate, setDeliverableToCreate] = useState<Partial<Omit<OrderWorkunit, 'id'>>>({
    client: order?.tmclients ? order.tmclients[0] : null,
    client_id: order?.tmclients ? order.tmclients[0].id : null,
    consultant: userRoles.isConsultant ? appState.user : null,
    order_id: state.order?.id,
    purchase_order: state.order?.ref_order,
  });
  const [currentTab, setCurrentTab] = useState<DeliverableTabOption>(DeliverableTabOption.CUSTOM);
  const [frequencyDate, setFrequencyDate] = useState<{
    start_date: string | null;
    end_date: string | null;
  }>({
    start_date: null,
    end_date: null,
  });
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isAllowedToChangeUserInThisScope, setIsAllowedToChangeUserInThisScope] = useState(false);
  const [frequencySelected, setfrequencySelected] = useState(false);

  const orderScopes = state.order_scopes;

  useEffect(() => {
    function isValidDate(dateString: string | null) {
      return moment(dateString).isValid();
    }

    const isFormCompleted = () => {
      if (
        deliverableToCreate?.scope &&
        deliverableToCreate?.workunit_name &&
        deliverableToCreate.client &&
        deliverableToCreate.consultant &&
        deliverableToCreate['mission-frequency'] &&
        ((!frequencyWeekly() &&
          !frequencyMonthly() &&
          deliverableToCreate.forecast_date &&
          deliverableToCreate.forecast_date !== null) ||
          ((frequencyWeekly() || frequencyMonthly()) &&
            isValidDate(frequencyDate.end_date) &&
            isValidDate(frequencyDate.start_date) &&
            moment(frequencyDate?.end_date).isSameOrAfter(moment(frequencyDate?.start_date))))
      ) {
        return true;
      }

      return false;
    };
    if (isFormCompleted()) {
      setIsCreateButtonDisabled(false);
    } else if (!isFormCompleted()) setIsCreateButtonDisabled(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deliverableToCreate, frequencyDate.end_date, frequencyDate.start_date]);
  useEffect(() => {
    if (userRoles.isConsultant) setIsAllowedToChangeUserInThisScope(false);
    const isTLButNotOnHisScope = !orderScopes?.find(
      (os) => os.scope_id === deliverableToCreate.scope_id && os.accountable_id === appState.user?.id
    );

    if (userRoles.isTeamLeader && isTLButNotOnHisScope) setIsAllowedToChangeUserInThisScope(false);
    else if (!userRoles.isConsultant) setIsAllowedToChangeUserInThisScope(true);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deliverableToCreate.scope_id]);

  const handleDateChange = (name: string, date: Moment | null) => {
    if (name === 'forecast_date') {
      if (date)
        return setDeliverableToCreate({
          ...deliverableToCreate,
          [name]: moment(date)?.startOf('day').format('YYYY-MM-DD'),
        });
      return setDeliverableToCreate({ ...deliverableToCreate, [name]: null });
    }

    if (date)
      return setFrequencyDate({
        ...frequencyDate,
        [name]: moment(date as MomentInput)
          .startOf('day')
          .format('YYYY-MM-DD'),
      });

    return setFrequencyDate({
      ...frequencyDate,
      [name]: null,
    });
  };

  const handleSelectOptions = (e: SelectTypeOptions | null, property: PropertyNameOptions) => {
    if (e && property === 'scope')
      setDeliverableToCreate({ ...deliverableToCreate, scope: e as Scope, scope_id: e.id });
    if (!e && property === 'scope')
      setDeliverableToCreate({ ...deliverableToCreate, scope: undefined, scope_id: null });
    if (e && property === 'workunit') {
      setDeliverableToCreate({
        ...deliverableToCreate,
        workunit: e as Workunit,
        workunit_id: e.id,
        price: /* ((e as Workunit)?.complexity as CatalogComplexity)?.price ?? undefined */ undefined,
        workunit_complexity_id: /* ((e as Workunit)?.complexity as CatalogComplexity)?.id ?? undefined */ undefined,
        /* complexity: (e as Workunit)?.complexity as CatalogComplexity, */
        complexity_name: ((e as Workunit)?.complexity as CatalogComplexity).name,
      });
    }
    if (!e && property === 'workunit')
      setDeliverableToCreate({ ...deliverableToCreate, workunit: undefined, workunit_id: undefined, price: undefined });
  };

  const handleSelectConsultant = (consultant: User | null | undefined, reason: string | undefined) => {
    if (reason === 'clear') {
      resetSelectedConsultant();
    }
    if (consultant) {
      setDeliverableToCreate({ ...deliverableToCreate, consultant, consultant_id: consultant.id });
    }
  };

  const handleSelectDeliveryManager = (deliveryManager: User | null | undefined, reason: string | undefined) => {
    if (reason === 'clear') {
      resetSelectedDM();
    }
    if (deliveryManager) {
      setDeliverableToCreate({
        ...deliverableToCreate,
        delivery_manager: [deliveryManager],
        delivery_manager_ids: [deliveryManager.id],
      });
    }
  };

  const resetSelectedDM = () => {
    setDeliverableToCreate({ ...deliverableToCreate, delivery_manager: [], delivery_manager_ids: [] });
  };

  const resetSelectedConsultant = () => {
    setDeliverableToCreate({ ...deliverableToCreate, consultant_id: null, consultant: null });
  };

  // eslint-disable-next-line consistent-return
  const handleSelectClient = (client: User | null | undefined, reason: string | undefined) => {
    if (reason === 'clear') {
      resetSelectedClient();
    }
    if (client) {
      setDeliverableToCreate({ ...deliverableToCreate, client, client_id: client.id });
    }
  };

  const resetSelectedClient = () => {
    setDeliverableToCreate({ ...deliverableToCreate, client_id: null, client: null });
  };
  const { makeCall } = useApi();
  const submit = async (e?: { preventDefault: () => void }) => {
    e?.preventDefault();
    setIsLoading(true);
    setIsCreateButtonDisabled(true);
    await makeCall(
      createDeliverables(deliverableToCreate, true, currentTab, frequencyDate),
      'Unable to create deliverable',
      'deliverableCreation'
    );

    closeDrawer();
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>, paramFilter?: MissionFrequency[] | MissionStatus[]) => {
    e.preventDefault();
    if (paramFilter)
      return setDeliverableToCreate({
        ...deliverableToCreate,
        [e.currentTarget.name]: paramFilter.filter((param) => Number(e.currentTarget.value) === param.id)[0],
      });
    return setDeliverableToCreate({ ...deliverableToCreate, [e.currentTarget.name]: e.currentTarget.value });
  };

  const closeDrawer = () => {
    dispatch(closeDrawerAction());
  };
  const frequencyWeekly = useCallback(
    () => (deliverableToCreate['mission-frequency'] as MissionFrequency | undefined)?.name === 'Weekly',
    [deliverableToCreate]
  );

  const frequencyMonthly = useCallback(
    () => (deliverableToCreate['mission-frequency'] as MissionFrequency | undefined)?.name === 'Monthly',
    [deliverableToCreate]
  );

  useEffect(() => {
    setfrequencySelected(frequencyMonthly() || frequencyWeekly());
  }, [deliverableToCreate, frequencyMonthly, frequencyWeekly]);
  return (
    <LocalizationProvider dateAdapter={MomentUtils}>
      <form className="deliverable-creation-form" onSubmit={submit}>
        <>
          <>
            <Box sx={{ mb: 3 }}>
              <InputLabel htmlFor="workunit_reference">{t('Contract_reference')}</InputLabel>
              <TextField
                name="workunit_reference"
                id="workunit_reference"
                placeholder={t('Contract_reference')}
                onChange={(e) => handleChange(e as any)}
                value={deliverableToCreate?.workunit_reference ?? ''}
                fullWidth
                disabled={loadings.api > 0}
              />
            </Box>

            <Box sx={{ mb: 3 }}>
              <InputLabel htmlFor="workunit_name">{t('Deliverable_name')} *</InputLabel>
              <TextField
                name="workunit_name"
                id="workunit_name"
                className="input-section"
                placeholder={t('Deliverable_name')}
                onChange={(e) => handleChange(e as any)}
                value={deliverableToCreate?.workunit_name}
                required
                disabled={loadings.api > 0}
                fullWidth
              />
            </Box>
          </>

          <Box sx={{ mb: 3 }}>
            <InputLabel htmlFor="content">{t('Content')}</InputLabel>
            <TextField
              name="content"
              id="content"
              className="active-blue background-grey no-border deliverables-input "
              value={deliverableToCreate.content ?? ''}
              onChange={(e) => setDeliverableToCreate({ ...deliverableToCreate, [e.target.name]: e.target.value })}
              fullWidth
              disabled={loadings.api > 0}
              multiline
              rows={3}
            />
          </Box>

          <ContentMultiple orderWorkunit={deliverableToCreate as OrderWorkunit} onchange={setDeliverableToCreate} />
          <Box sx={{ mb: 3 }}>
            <InputLabel htmlFor="purchase_order">{t('purchase_order')}</InputLabel>
            <TextField
              name="purchase_order"
              id="purchase_order"
              placeholder={t('purchase_order')}
              onChange={(e) => handleChange(e as any)}
              value={deliverableToCreate?.purchase_order ?? ''}
              fullWidth
              required
              disabled={loadings.api > 0}
            />
          </Box>
          <Box sx={{ mb: 3 }}>
            <AutocompleteCustom
              label={t('Scope')}
              options={scopes}
              optionsLabels="name"
              disabled={loadings.api > 0}
              required
              handleSelectOptions={handleSelectOptions}
              selectedOption={deliverableToCreate?.scope || null}
              propertyName="scope"
            />
          </Box>
          {deliverableToCreate?.scope && (
            <>
              <Box sx={{ mb: 3 }}>
                <InputLabel htmlFor="mission_frequency_id">{t('Frequency')} *</InputLabel>
                <RadioGroup
                  aria-label="mission_frequency_id"
                  name="mission-frequency"
                  id="mission_frequency_id"
                  row
                  onChange={(e) => handleChange(e, state.frequencies)}
                >
                  {state.frequencies
                    .filter((x) => x.name !== 'Other')
                    .map((frequency) => (
                      <FormControlLabel
                        key={frequency.id}
                        value={frequency.id?.toString()}
                        className={`wrapped ${isActiveFrequency(deliverableToCreate, frequency)}`}
                        control={<Radio color="primary" />}
                        label={t(frequency.name) as string}
                        disabled={loadings.api > 0}
                      />
                    ))}
                </RadioGroup>
              </Box>
              {frequencySelected && (
                <Grid container spacing={4} sx={{ mb: 3 }}>
                  <Grid item xs={12} md={6}>
                    <InputDate
                      label={t('Start_frequency_date')}
                      name="start_date"
                      id="start_date"
                      placeholder="DD/MM/YYYY"
                      onchange={handleDateChange}
                      value={frequencyDate.start_date || null}
                      required
                      min={moment(state.order?.start_date).toString()}
                      disabled={loadings.api > 0}
                      max={moment(state.order?.end_date).add('1', 'year').toString()}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <InputDate
                      label={t('End_frequency_date')}
                      name="end_date"
                      id="end_date"
                      disabled={loadings.api > 0}
                      placeholder="DD/MM/YYYY"
                      onchange={handleDateChange}
                      value={frequencyDate.end_date || null}
                      min={moment(frequencyDate.start_date).toString()}
                      required
                      max={moment(state.order?.end_date).add('1', 'year').toString()}
                    />
                  </Grid>
                </Grid>
              )}
              {!frequencySelected && (
                <Box sx={{ mb: 3 }}>
                  <InputDate
                    label={t('Date_delivery')}
                    name="forecast_date"
                    id="forecast_date"
                    placeholder="DD/MM/YYYY"
                    onchange={handleDateChange}
                    value={deliverableToCreate.forecast_date || null}
                    min={moment(state.order?.start_date).toString()}
                    max={moment(state.order?.end_date).add('1', 'year').toString()}
                    required
                    disabled={loadings.api > 0}
                  />
                </Box>
              )}
              {state.consultants && (
                <Box sx={{ mb: 3 }}>
                  <SelectUser
                    users={state.order?.tmconsultants ? state.order?.tmconsultants : []}
                    selectedUser={deliverableToCreate?.consultant}
                    onSelectUser={handleSelectConsultant}
                    label={t('Consultant')}
                    required
                    disabled={userRoles.isConsultant || loadings.api > 0}
                  />
                </Box>
              )}
              {state.deliveryManagers?.length && (
                <Box sx={{ mb: 3 }}>
                  <SelectUser
                    users={state.order?.tmaccountables ? state.order?.tmaccountables : []}
                    selectedUser={deliverableToCreate?.delivery_manager?.[0] || null}
                    onSelectUser={handleSelectDeliveryManager}
                    label={t('Delivery_manager')}
                    disabled={loadings.api > 0}
                  />
                </Box>
              )}
              {state.customers && (
                <Box sx={{ mb: 3 }}>
                  <SelectUser
                    users={state.customers}
                    selectedUser={deliverableToCreate?.client || null}
                    onSelectUser={handleSelectClient}
                    label={t('Client')}
                    required
                  />
                </Box>
              )}
            </>
          )}
        </>

        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <LoadingButton
            loading={loadings.api > 0}
            disabled={isCreateButtonDisabled || !!loadings.api}
            onClick={submit}
            startIcon={<Add />}
            variant="contained"
            color="success"
          >
            {t('Add_this_new_deliverable')}
          </LoadingButton>
        </Box>
      </form>
    </LocalizationProvider>
  );
};

export default TnMDeliverableCreation;
