/* eslint-disable @typescript-eslint/no-explicit-any */
// eslint-disable-next-line import/order
import { RemoveRedEye } from '@mui/icons-material';
import { Avatar, Box, Card, CardContent, Grid, IconButton, Typography } from '@mui/material';
import { green, purple, red } from '@mui/material/colors';
import CatalogApiService from 'api/CatalogApiService';
import WorkunitApiService from 'api/WorkunitApiService';
import { CalendarIcon, ClipboardIcon, ClockIcon, EditIcon, MazeIcon, WorkunitsIcon } from 'components/CustomIcons';
import { Layout } from 'components/index';
import WorkUnitsList from 'features/Workunits/Workunits';
import { formatDateLitteral, stringToColor } from 'helpers/format';
import useApi from 'hooks/useApi';
import useUserRoles from 'hooks/useUserRoles';
// eslint-disable-next-line import/order
import moment from 'moment';
/* eslint-disable no-unused-expressions */
import React, { useEffect, useReducer, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps, useParams } from 'react-router-dom';
import {
  loadCatalogAction,
  loadCatalogComplexitiesAction,
  loadCatalogsAction,
  loadWorkunitsAction,
} from 'store/actions/appActions';
import { addLoadingAction, removeLoadingAction } from 'store/actions/loadingsActions';
import { useSelector } from 'store/hooks';
import { useAppState } from 'store/Provider';
import Catalog from 'types/entities/Catalog';
import User from 'types/entities/User';
import './CatalogDetails.scss';
import CatalogUpsert from './CatalogUpsert';
import EditComplexity from './EditComplexity';

const getAvatar = (user: User | undefined) => {
  if (user)
    return user?.avatar_uri ? (
      <Avatar src={user.avatar_uri} />
    ) : (
      <Avatar
        sx={{
          backgroundColor: stringToColor(user.first_name),
          color: stringToColor(user.first_name, 'color'),
        }}
      >{`${user.last_name.charAt(0).toUpperCase()}${user.first_name.charAt(0).toUpperCase()}`}</Avatar>
    );
  return <Avatar />;
};

type Params = {
  catalogId: string;
};

const CatalogDetails: React.FC<RouteComponentProps<Params>> = ({ match }) => {
  const { dispatch } = useAppState();
  const appState = useSelector((state) => state.app);
  const userRoles = useUserRoles();
  const loadings = useSelector((state) => state.loadings);
  const [open, dispatchOpen] = useReducer((prev) => !prev, false);
  const [upsertCatalogOpen, setUpsertCatalogOpen] = useReducer((prev) => !prev, false);
  const { catalogId } = useParams<Params>();
  const [t] = useTranslation();
  const { makeCall } = useApi();
  const [isComplexityLoading] = useState<boolean>(false);

  // Fetch catalog's workunits and set it in context
  useEffect(() => {
    const setActiveWorkunits = async () => {
      const res = await makeCall(
        WorkunitApiService.get(Number(catalogId), { join: ['devise', 'complexity'], size: '-1' }),
        'Fetch workunits from active catalog failed'
      );
      dispatch(loadWorkunitsAction(res.datas));
    };
    setActiveWorkunits();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [match.params.catalogId]);

  useEffect(() => {
    const setActiveCatalogCall = async () => {
      dispatch(addLoadingAction('setActiveCatalogCall'));
      const catalog = await makeCall(
        CatalogApiService.fetchById(Number(match.params.catalogId), { join: ['contact', 'client'] }),
        'Fetch catalog failed'
      );
      dispatch(loadCatalogAction(catalog));
      dispatch(removeLoadingAction('setActiveCatalogCall'));
    };
    setActiveCatalogCall();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [match.params.catalogId]);

  useEffect(() => {
    const getCatalogComplexities = async () => {
      dispatch(addLoadingAction('getCatalogComplexities'));
      const complexities = await makeCall(
        CatalogApiService.getCatalogComplexities(Number(match.params.catalogId)),
        'Fetch catalog complexities failed'
      );
      dispatch(loadCatalogComplexitiesAction(complexities));
      dispatch(removeLoadingAction('getCatalogComplexities'));
    };

    getCatalogComplexities();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [match.params.catalogId]);

  const handleUpdateCatalog = async (catalog: Partial<Catalog>) => {
    const { complexities, client, contact, ...catalogToSave } = { ...appState.catalog, ...catalog };
    const catalogSaved = await makeCall(
      CatalogApiService.update(appState.catalog?.id as number, {
        ...catalogToSave,
        validity_from: moment(catalog?.validity_from).format('YYYY-MM-DD'),
        validity_to: moment(catalog?.validity_to).format('YYYY-MM-DD'),
      }),
      'Update catalog failed'
    );
    dispatch(loadCatalogAction({ ...appState.catalog, ...catalogSaved, client, complexities, contact }));
    dispatch(
      loadCatalogsAction(
        appState.catalogs?.map((cata) =>
          cata?.id !== catalogSaved?.id ? cata : { ...appState.catalog, ...catalogSaved, client, complexities, contact }
        )
      )
    );
  };

  const handleClickOpenDispatchCatalogUpsert = () => {
    if (userRoles.isAdmin) setUpsertCatalogOpen();
  };

  return (
    <Layout name={appState.catalog?.reference as string} path={`/catalogs/${appState.catalog?.reference}`}>
      {loadings.setActiveCatalogCall ? (
        <> </>
      ) : (
        <>
          <Grid container spacing={4} sx={{ mb: 10 }}>
            <Grid item xs={12}>
              <Card sx={{ position: 'relative' }}>
                <CardContent sx={{ paddingRight: '70px' }}>
                  <Grid container spacing={4}>
                    <Grid item xs={12} md={6} lg={3}>
                      <Box sx={{ display: 'flex' }}>
                        <Box sx={{ mr: 1 }}>
                          <Avatar sx={{ bgcolor: 'primary.light' }}>
                            <CalendarIcon sx={{ color: 'primary.main' }} />
                          </Avatar>
                        </Box>
                        <Box>
                          <Typography sx={{ color: 'text.secondary', fontWeight: 500 }}>{t('Validity')}</Typography>
                          <Typography sx={{ fontWeight: 600 }}>
                            {`${formatDateLitteral(appState.catalog?.validity_from || new Date().toISOString())}`}
                          </Typography>
                          <Typography sx={{ fontWeight: 600 }}>
                            {formatDateLitteral(appState.catalog?.validity_to || new Date().toISOString())}
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                      <Box sx={{ display: 'flex' }}>
                        <Box sx={{ mr: 1 }}>
                          <Avatar sx={{ bgcolor: 'primary.light' }}>
                            <ClockIcon sx={{ color: 'primary.main' }} />
                          </Avatar>
                        </Box>
                        <Box>
                          <Typography sx={{ color: 'text.secondary', fontWeight: 500 }}>{t('Last_updated')}</Typography>
                          <Typography sx={{ fontWeight: 600 }}>
                            {formatDateLitteral(appState.catalog?.updated_at || new Date().toISOString())}
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                      <Box sx={{ display: 'flex' }}>
                        {appState.catalog?.client && <Box sx={{ mr: 1 }}>{getAvatar(appState.catalog.client)}</Box>}
                        <Box>
                          <Typography sx={{ color: 'text.secondary', fontWeight: 500 }}>
                            {t('Client_catalog')}
                          </Typography>
                          <Typography sx={{ fontWeight: 600 }}>
                            {appState.catalog?.client
                              ? `${appState.catalog?.client?.last_name.toLocaleUpperCase()} ${
                                  appState.catalog?.client?.first_name
                                }`
                              : 'Not specified'}
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                      <Box sx={{ display: 'flex' }}>
                        {appState.catalog?.contact && <Box sx={{ mr: 1 }}>{getAvatar(appState.catalog.contact)}</Box>}
                        <Box>
                          <Typography sx={{ color: 'text.secondary', fontWeight: 500 }}>
                            {t('Internal_contact')}
                          </Typography>
                          <Typography sx={{ fontWeight: 600 }}>
                            {appState.catalog?.contact
                              ? `${appState.catalog?.contact?.last_name.toLocaleUpperCase()} ${
                                  appState.catalog?.contact?.first_name
                                }`
                              : 'Not specified'}
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                </CardContent>
                {userRoles.isAdmin && (
                  <IconButton
                    aria-label="settings"
                    color="primary"
                    sx={{ position: 'absolute', top: 10, right: 10 }}
                    onClick={handleClickOpenDispatchCatalogUpsert}
                  >
                    <EditIcon fontSize="small" />
                  </IconButton>
                )}
              </Card>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={4}>
                <Grid item xs={12} lg={4}>
                  <Card>
                    <CardContent>
                      <Box sx={{ display: 'flex' }}>
                        <Box sx={{ mr: 1 }}>
                          <Avatar sx={{ bgcolor: green[50] }}>
                            <WorkunitsIcon sx={{ color: green[300] }} />
                          </Avatar>
                        </Box>
                        <Box>
                          <Typography sx={{ color: 'text.secondary', fontWeight: 500 }}>{t('Workunits')}</Typography>
                          <Typography sx={{ fontWeight: 600, fontSize: '2rem', lineHeight: 1 }}>
                            {appState.catalog?.nb_workunits ?? '0'}
                          </Typography>
                        </Box>
                      </Box>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12} lg={4}>
                  <Card>
                    <CardContent>
                      <Box sx={{ display: 'flex' }}>
                        <Box sx={{ mr: 1 }}>
                          <Avatar sx={{ bgcolor: red[50] }}>
                            <ClipboardIcon sx={{ color: red[300] }} />
                          </Avatar>
                        </Box>
                        <Box>
                          <Typography sx={{ color: 'text.secondary', fontWeight: 500 }}>{t('Orders')}</Typography>
                          <Typography sx={{ fontWeight: 600, fontSize: '2rem', lineHeight: 1 }}>
                            {appState.catalog?.count_linked_active_orders ?? 0}
                          </Typography>
                        </Box>
                      </Box>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12} lg={4}>
                  <Card sx={{ position: 'relative' }}>
                    <CardContent sx={{ paddingRight: '70px' }}>
                      <Box sx={{ display: 'flex' }}>
                        <Box sx={{ mr: 1 }}>
                          <Avatar sx={{ bgcolor: purple[50] }}>
                            <MazeIcon sx={{ color: purple[300] }} />
                          </Avatar>
                        </Box>
                        <Box>
                          <Typography sx={{ color: 'text.secondary', fontWeight: 500 }}>{t('Complexities')}</Typography>
                          <Typography sx={{ fontWeight: 600, fontSize: '2rem', lineHeight: 1 }}>
                            {
                              appState.catalogComplexities.filter((complexity) => complexity && !complexity.is_archived)
                                .length
                            }
                          </Typography>
                        </Box>
                      </Box>
                    </CardContent>

                    <IconButton
                      aria-label="settings"
                      color="primary"
                      sx={{ position: 'absolute', top: 10, right: 10 }}
                      onClick={dispatchOpen}
                    >
                      {userRoles.isAdmin ? <EditIcon fontSize="small" /> : <RemoveRedEye />}
                    </IconButton>
                  </Card>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {appState.catalog ? (
            <EditComplexity open={open} dispatchOpen={dispatchOpen} isComplexityLoading={isComplexityLoading} />
          ) : (
            <></>
          )}
          <WorkUnitsList />
          {/* <Button onClick={() => dispatchOpenWorkunits()} className="space-between">
            {t('Catalog_workunits')}

            <IconComponent name={openWorkunits ? 'chevronUp' : 'chevronDown'} />
          </Button>
          <Divider color="#D8D8D8" minWidth="100%" /> */}

          {/* {openWorkunits && <WorkUnitsList />} */}
        </>
      )}
      {upsertCatalogOpen && (
        <CatalogUpsert
          open={upsertCatalogOpen}
          onClose={setUpsertCatalogOpen}
          activeCatalog={appState.catalog}
          submit={handleUpdateCatalog}
          catalogDetails
        />
      )}
    </Layout>
  );
};

export default CatalogDetails;
