import { Tooltip, Typography } from '@mui/material';
import { formatDateLitteral } from 'helpers/format';
import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import OrderWorkunit from 'types/entities/OrderWorkunit';

const ForcastedDateCell = React.memo((row: OrderWorkunit) => {
  const [t] = useTranslation();

  return (
    <Tooltip
      title={
        <>
          {row?.forecast_date && formatDateLitteral(moment(row?.forecast_date).format('YYYY/MM/DD').toString())}
          {!row?.forecast_date && row?.delivery_date && formatDateLitteral(row?.delivery_date)}
          {!row?.delivery_date && !row?.forecast_date && t('N/A')}
        </>
      }
    >
      <Typography variant="body2" noWrap>
        {row?.forecast_date && formatDateLitteral(moment(row?.forecast_date).format('YYYY/MM/DD').toString())}
        {!row?.forecast_date && row?.delivery_date && formatDateLitteral(row?.delivery_date)}
        {!row?.delivery_date && !row?.forecast_date && t('N/A')}
      </Typography>
    </Tooltip>
  );
});

export default ForcastedDateCell;
