import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Button, Grid } from '@mui/material';
import CatalogApiService from 'api/CatalogApiService';
import { handleApiError } from 'api/errors';
import DashedButton from 'components/DashedButton';
import EmptyState from 'components/EmptyState';
import { Layout } from 'components/index';
import CatalogCard from 'features/Catalogs/CatalogCard';
import useApi from 'hooks/useApi';
import useUserRoles from 'hooks/useUserRoles';
import React, { useEffect, useReducer, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps, useHistory, useLocation } from 'react-router-dom';
import { loadCatalogArchivedAction, loadCatalogsAction } from 'store/actions/appActions';
import { useSelector } from 'store/hooks';
import { useAppState } from 'store/Provider';
import Catalog from 'types/entities/Catalog';
import { Catalogs } from '../index';
import CatalogUpsert from './CatalogUpsert';

const CatalogPage: React.FC<RouteComponentProps> = ({ match }) => {
  const { dispatch } = useAppState();
  const appState = useSelector((state) => state.app);
  const userRoles = useUserRoles();
  const { makeCall } = useApi();
  const [showArchivedCatalogs, setShowArchivedCatalogs] = React.useState(true);
  const [catalogsLoading, setCatalogsLoading] = React.useState(false);
  const activeCatalogs = useSelector((state) => state.app.catalogs);
  // TODO : store
  const archivedCatalogs = useSelector((state) => state.app.archivedCatalogs);
  const [catalogsPage] = useState(0);

  const [catalogsArchivedLoading, setCatalogsArchivedLoading] = useState(false);
  const [catalogsArchivedPage] = useState(0);

  const history = useHistory();
  const [t] = useTranslation();
  const [isCatalogCreationOpen, setIsCatalogCreationOpen] = useReducer((prev) => !prev, false);
  const handleClick = () => {
    setShowArchivedCatalogs(!showArchivedCatalogs);
  };

  const [isCreationQuote, setIsCreationQuote] = useState(false);

  function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
  }

  const query = useQuery();

  useEffect(() => {
    if (query.get('create_quote') === 'true') setIsCreationQuote(true);
    if (query.get('create_quote') === 'false' || !query.get('create_quote')) setIsCreationQuote(false);
  }, [query]);

  // Fetch customer's catalogs.
  useEffect(() => {
    const fetchCurrentCustomerCatalogs = async () => {
      setCatalogsLoading(true);
      const res = await makeCall(
        CatalogApiService.fetchList([
          'reference',
          'updated_at',
          'validity_from',
          'validity_to',
          'nb_workunits',
          'is_archived',
          'id',
          'customer_id',
        ])
      );
      if (res) dispatch(loadCatalogsAction(res));
      setCatalogsLoading(false);
    };

    if (appState.customer?.id) {
      fetchCurrentCustomerCatalogs();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appState.customer?.id, catalogsPage, isCreationQuote]);

  // Fetch archived customer's catalogs.
  useEffect(() => {
    if (isCreationQuote) return;
    const fetchCurrentCustomerCatalogs = async () => {
      setCatalogsArchivedLoading(true);
      const res = await makeCall(
        CatalogApiService.fetchList(
          [
            'reference',
            'updated_at',
            'validity_from',
            'validity_to',
            'nb_workunits',
            'is_archived',
            'id',
            'customer_id',
          ],
          true
        )
      );
      if (res) dispatch(loadCatalogArchivedAction(res));

      setCatalogsArchivedLoading(false);
    };

    if (appState.customer?.id) {
      fetchCurrentCustomerCatalogs();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appState.customer?.id, catalogsArchivedPage]);

  const handleCreateCatalog = async (catalog: Partial<Catalog>) => {
    try {
      const { client, contact, ...catalogToSave } = { ...catalog, customer_id: appState.customer?.id };
      const newCatalog = await makeCall(
        CatalogApiService.create({
          ...catalogToSave,
          validity_from: catalogToSave?.validity_from ?? new Date(),
          validity_to: catalogToSave?.validity_to ?? new Date(),
        }),
        'Error while creating catalog'
      );
      if (newCatalog) {
        dispatch(loadCatalogsAction([...(appState.catalogs as Catalog[]), newCatalog]));
        history.push(`/${appState.customer?.slug}/catalogs/${newCatalog.id}`);
      }
    } catch (error) {
      handleApiError(error);
    }
  };
  return (
    <Layout name={isCreationQuote ? t('create_new_quote') : t('Catalogs')} path="/Catalogs">
      <Grid container spacing={4} sx={{ mb: 5 }}>
        {!catalogsLoading && !isCreationQuote && !userRoles.isManager && (
          <Grid item xs={12} sm={6} md={4} lg={3} xl={2} sx={{ display: 'flex' }}>
            <DashedButton content={t('Add_catalog')} onclick={() => setIsCatalogCreationOpen()} />
          </Grid>
        )}
        {!catalogsLoading && activeCatalogs && activeCatalogs.length > 0 && (
          <>
            {activeCatalogs.map((catalog, index) => (
              <React.Fragment key={`catalog_${String(index)}`}>
                <Grid item xs={12} sm={6} md={4} lg={3} xl={2} sx={{ display: 'flex' }}>
                  {!catalog.is_archived && <CatalogCard catalog={catalog} />}
                </Grid>
              </React.Fragment>
            ))}
          </>
        )}
      </Grid>

      {!isCreationQuote && !catalogsLoading && (
        <>
          <Button
            endIcon={showArchivedCatalogs ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            onClick={() => handleClick()}
            fullWidth
            sx={{ mb: 5 }}
          >
            Your Archived Catalogs
          </Button>
          {showArchivedCatalogs && (
            <Grid container spacing={4}>
              {!catalogsArchivedLoading && !archivedCatalogs && <div>No catalogs</div>}
              {!catalogsArchivedLoading && archivedCatalogs && archivedCatalogs.length > 0 ? (
                <>
                  {archivedCatalogs.map((catalog, index) => (
                    <React.Fragment key={`catalogarchived_${String(index)}`}>
                      <Grid item xs={12} sm={6} md={4} lg={3} xl={2} sx={{ display: 'flex' }}>
                        {catalog.is_archived && <CatalogCard catalog={catalog} />}
                      </Grid>
                    </React.Fragment>
                  ))}
                </>
              ) : (
                !catalogsArchivedLoading && <EmptyState title={t('no_archived_catalogs')} />
              )}
            </Grid>
          )}
        </>
      )}
      {isCatalogCreationOpen && (
        <CatalogUpsert
          open={isCatalogCreationOpen}
          onClose={setIsCatalogCreationOpen}
          submit={handleCreateCatalog}
          catalogDetails={false}
        />
      )}
    </Layout>
  );
};

export default CatalogPage;
