import { Box, Button, Grid, Typography } from '@mui/material';
import ResourceAPI from 'api/ResourceAPI';
import UserApiService from 'api/UserApiService';
import { Layout, Loader } from 'components';
import { PropertyNameOptions, SelectTypeOptions } from 'components/Select';
import useApi from 'hooks/useApi';
import moment, { Moment } from 'moment';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { setSnackbarAction } from 'store/actions/snackbarActions';
import { useSelector } from 'store/hooks';
import { useAppState } from 'store/Provider';
import Order from 'types/entities/Order';
import User from 'types/entities/User';
import OrderForm from '../OrderForm/OrderForm';
import { OrderType } from 'types/enums/OrderType';

// import './CreationOrder.scss';

interface RouteParams {
  catalog_id: string;
}

const CreationOrder: React.FC<RouteComponentProps<RouteParams>> = ({ match }) => {
  const { dispatch } = useAppState();
  const appState = useSelector((state) => state.app);
  const [t] = useTranslation();
  const [newOrder, setNewOrder] = useState<Partial<Order>>({
    start_date: moment().toISOString(),
    end_date: moment().toISOString(),
    is_sign_mandatory: true,
    client_connection: appState.customer?.client_connection,
  });
  const history = useHistory();
  const { makeCall } = useApi();

  const handleChange = (e: React.FormEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
    e.preventDefault();
    return setNewOrder({ ...newOrder, [e.currentTarget.name]: e.currentTarget.value });
  };

  const handleSelectClient = (client: User | null | undefined) => {
    if (client) return setNewOrder({ ...newOrder, client, client_id: client.id });
    return setNewOrder({ ...newOrder, client: undefined, client_id: undefined });
  };
  const handleSelectRepresentative = (representative: User | null | undefined) => {
    if (representative) return setNewOrder({ ...newOrder, delegue: representative, delegue_id: representative.id });
    return setNewOrder({ ...newOrder, delegue: undefined, delegue_id: undefined });
  };
  const handleSelectOptions = (e: SelectTypeOptions | null, property: PropertyNameOptions) => {
    if (e && property === 'affair') return setNewOrder({ ...newOrder, [`${property}`]: e, [`${property}_id`]: e.id });
    return setNewOrder({ ...newOrder, affair: undefined, affair_id: undefined });
  };
  const handleDateChange = (name: string, date: Moment | null) =>
    setNewOrder({ ...newOrder, [name]: date?.toISOString() });

  const [isInValidation, setIsInValidation] = useState(false);
  const [isMissingInputs, setIsMissingInputs] = useState(false);
  const [creators, setCreators] = useState<User[]>([]);

  useEffect(() => {
    const getCreators = async () => {
      const creatorsFetched = await UserApiService.fetchPossiblesDelegues();
      setCreators(creatorsFetched);
    };
    getCreators();
  }, []);

  useEffect(() => {
    setIsMissingInputs(
      !(!!newOrder.ref_spec && !!newOrder.name_spec) || moment(newOrder?.end_date).isBefore(newOrder?.start_date)
    );
  }, [newOrder]);
  const handleSubmitNewOrder = async () => {
    setIsInValidation(true);
    const { affair, client, ...orderToSave } = newOrder as Order;
    try {
      const res = await ResourceAPI.post('orders', {
        ...orderToSave,
        client_id: newOrder?.client_id,
        catalog_id: match?.params.catalog_id,
        customer_id: appState.customer?.id,
        affair_name: affair?.name || null,
        affair_otp: affair?.otp || null,
        affair_id: affair?.id || null,
        creator_id: appState.user?.id,
        order_type_id: OrderType.CATALOG,
      });
      if (res) {
        history.push(`/${appState.customer?.slug}/catalog/${match?.params.catalog_id}/order/${res.data.order.id}`);
      }
    } catch (error) {
      const result = (error as Error).message;

      if (result) {
        dispatch(
          setSnackbarAction({
            message: `Fetch order-workunits from active catalog failed : ${result}`,
            open: true,
            severity: 'error',
          })
        );
      } else {
        dispatch(
          setSnackbarAction({
            message: `Fetch order-workunits from active catalog failed : ${error}`,
            open: true,
            severity: 'error',
          })
        );
      }
    } finally {
      setIsInValidation(false);
    }
  };
  const handleSwitchSign = (value: boolean) => {
    setNewOrder({ ...newOrder, is_sign_mandatory: value });
  };

  const handleClientConnectionSwitch = (value: boolean) => {
    setNewOrder({ ...newOrder, client_connection: value });
  };

  const [clients, setClients] = useState<User[]>([]);

  useEffect(() => {
    const getClients = async () => {
      if (appState.customer) {
        const res = await makeCall(
          UserApiService.getClientsOnCustomer(appState.customer?.id, { is_archived: 0, size: '-1' }),
          'Failed to retrieve clients'
        );
        setClients(res);
      }
    };

    getClients();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appState.customer]);

  return (
    <Layout name={t('Create_new_order')} path="Create_new_order">
      <OrderForm
        handleChange={handleChange}
        handleSelectClient={handleSelectClient}
        handleSelectRepresentative={handleSelectRepresentative}
        handleSelectOptions={handleSelectOptions}
        handleDateChange={handleDateChange}
        handleSwitchSign={handleSwitchSign}
        handleClientConnectionSwitch={handleClientConnectionSwitch}
        order={newOrder}
        creators={creators}
        clients={clients}
      />
      <Box sx={{ m: 'auto -30px -30px', p: '30px', backgroundColor: '#FFFFFF', boxShadow: '0px 3px 16px #B2BCD571' }}>
        <Grid container spacing={4} alignItems="center">
          <Grid item xs={12} md={6} lg={2}>
            <Typography variant="h4" color="neutral.main">
              {t('Affair_parameters')}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6} lg={2}>
            <Typography>{t('Draft_reference_text')}</Typography>
            <Typography variant="h6" color="primary.main">
              {newOrder?.draft_reference || '-'}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6} lg={2}>
            <Typography>{t('Name_spec')}</Typography>
            <Typography variant="h6" color="primary.main" sx={{ mb: 2 }}>
              {newOrder?.name_spec || '-'}
            </Typography>
            <Typography>{t('Ref_spec')}</Typography>
            <Typography variant="h6" color="primary.main">
              {newOrder?.ref_spec || '-'}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6} lg={2}>
            <Typography>{t('Engagement_date')}</Typography>
            <Typography variant="h6" color="primary.main" sx={{ mb: 2 }}>
              {moment(newOrder?.start_date).format('DD/MM/YYYY') || '-'} -{' '}
              {moment(newOrder?.end_date).format('DD/MM/YYYY') || ''}
            </Typography>
            <Typography>{t('Client')}</Typography>
            <Typography variant="h6" color="primary.main">
              {`${newOrder?.client?.last_name.toLocaleUpperCase() || '-'} ${newOrder?.client?.first_name || ''}`}{' '}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6} lg={2}>
            <Typography>{t('Affair_name')}</Typography>
            <Typography variant="h6" color="primary.main">
              {newOrder?.affair?.name || '-'}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6} lg={2}>
            <Button
              onClick={() => handleSubmitNewOrder()}
              disabled={isInValidation || isMissingInputs}
              color="success"
              variant="contained"
            >
              {isInValidation ? <Loader size={20} className="white-loader" /> : t('Validate')}
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Layout>
  );
};

export default CreationOrder;
