import ButtonUnstyled from '@mui/base/ButtonUnstyled';
import ExitToApp from '@mui/icons-material/ExitToApp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Avatar, Box, capitalize, Menu, MenuItem } from '@mui/material';
import ApiAuthService from 'api/AuthApiService';
import NotificationApiService from 'api/NotificationApiService';
import { stringToColor } from 'helpers/format';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { injectAlertsAction, loadTokenAction } from 'store/actions/appActions';
import { useSelector } from 'store/hooks';
import { useAppState } from 'store/Provider';
import User from 'types/entities/User';
import Alert from 'types/models/Alert';
import { Notification } from './Notifications';
import './UserTopBar.scss';

type UserTopBarProps = {
  activeUser: User;
};
const UserTopBar = (props: UserTopBarProps) => {
  const {
    activeUser: { avatar_uri, first_name, last_name, id },
  } = props;
  const { dispatch } = useAppState();
  const [t] = useTranslation();
  const listInnerRef = useRef<HTMLDivElement>(null);

  const alerts = useSelector((state) => state.app.alerts);

  const [currNotificationPage, setCurrNotificationPage] = useState(0);
  const [prevNotificationPage, setPrevNotificationPage] = useState(-1);
  const [lastNotificationList, setLastNotificationList] = useState(false);

  const [isAlertsLoading, setIsAlertsLoading] = useState(false);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleOnScroll = () => {
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
      if (scrollTop + clientHeight === scrollHeight && !isAlertsLoading) {
        setCurrNotificationPage(currNotificationPage + 1);
      }
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      setIsAlertsLoading(true);
      const notification = await NotificationApiService.Instance.getNotifications(id, currNotificationPage);
      if (!notification.data.datas.length) {
        setLastNotificationList(true);
        setIsAlertsLoading(false);
        return;
      }
      setPrevNotificationPage(currNotificationPage);
      setAlerts([...alerts, ...notification.data.datas]);
      setIsAlertsLoading(false);
    };
    if (
      !lastNotificationList &&
      prevNotificationPage !== currNotificationPage &&
      currNotificationPage > 0 &&
      !isAlertsLoading
    ) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currNotificationPage, lastNotificationList, prevNotificationPage]);

  const buttonDisconnectText = t('Disconnect');

  const setAlerts = (alerts: Alert[]) => {
    dispatch(injectAlertsAction(alerts));
  };

  const handleDisconnect = async () => {
    localStorage.removeItem('cognito-token');
    dispatch(loadTokenAction());
    const getUri = async () => {
      const uri = await ApiAuthService.getAuthAuthorization();
      window.location.href = uri;
    };
    await getUri();
  };
  const mountedRef = useRef<boolean>(true);

  useEffect(() => {
    async function getAlerts() {
      if (!mountedRef.current) return;
      const notification = await NotificationApiService.Instance.getNotifications(id, 0);
      if (notification && notification.data) setAlerts(notification.data.datas);
    }
    if (!alerts.length) {
      getAlerts();
    }
    const interval = setInterval(() => getAlerts(), 100000);
    clearInterval(interval);

    return () => {
      mountedRef.current = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  // useEffect(() => {
  //   console.log(alerts);
  // }, [alerts]);
  return (
    <div className="user-navbar">
      <Notification
        alerts={alerts}
        setAlerts={(idAlert: number) =>
          setAlerts(alerts.map((alert) => (alert.id !== idAlert ? alert : { ...alert, is_read: true })))
        }
        markAllAsReads={() => setAlerts(alerts.map((alert) => ({ ...alert, is_read: true })))}
        handleOnScroll={handleOnScroll}
        listRef={listInnerRef}
        isLoading={isAlertsLoading}
      />

      <ButtonUnstyled aria-haspopup="true" onClick={handleClick} className="user-menu">
        {!avatar_uri && (
          <Avatar
            style={{
              backgroundColor: stringToColor(first_name),
              color: stringToColor(first_name, 'color'),
            }}
            className="user-menu__avatar"
            variant="rounded"
          >{` ${capitalize(first_name.charAt(0))}${capitalize(last_name.charAt(0))}`}</Avatar>
        )}
        {avatar_uri && <Avatar src={avatar_uri} alt={t('Active_user_avatar')} />}
        <Box sx={{ display: { xs: 'none', md: 'block' } }}>
          <span className="user-menu__name">
            {`${capitalize(first_name.toLowerCase())} ${capitalize(last_name.toLowerCase())}`}
          </span>
        </Box>
        <KeyboardArrowDownIcon />
      </ButtonUnstyled>
      <Menu
        anchorEl={anchorEl}
        open={!!anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        onBackdropClick={() => setAnchorEl(null)}
      >
        <MenuItem onClick={() => handleDisconnect()}>
          {buttonDisconnectText}
          <ExitToApp color="primary" />
        </MenuItem>
      </Menu>
    </div>
  );
};
export default UserTopBar;
