class BaseApiService {
  axiosConfig: unknown;

  protected constructor() {
    this.getAxiosConfig();
  }

  // eslint-disable-next-line class-methods-use-this
  private getAxiosConfig() {
    return {
      baseURL: process.env.REACT_APP_SERVER_URL,
      headers: {
        Accept: 'application/json',
        'Access-Control-Allow-Origin': '*',
        'access-control-allow-headers':
          'Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With, X-Tenant, Tenant',
      },
    };
  }
}

export default BaseApiService;
