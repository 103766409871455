import { CssBaseline } from '@mui/material';
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import StylesProvider from '@mui/styles/StylesProvider';
import moment from 'moment-timezone';
import React from 'react';
import ReactDOM from 'react-dom';
import { theme } from 'styles/theme';
import App from './App';
import { AppStateProvider } from './store/Provider';
import './styles/index.scss';

moment.tz.setDefault('Europe/Paris');

if (`serviceWorker` in navigator) {
  navigator.serviceWorker.ready.then((registration) => {
    registration.unregister();

    if (caches) {
      // Service worker cache should be cleared with caches.delete()
      caches.keys().then(async (names) => {
        await Promise.all(names.map((name) => caches.delete(name)));
      });
    }
  });
  navigator.serviceWorker
    .getRegistrations()
    .then((registrations) => {
      if (registrations.length > 0)
        console.warn(
          `Warning: found one or more service workers present.`,
          `If your site isn't behaving as expected, you might want to remove these.`,
          registrations
        );
    })
    .catch((err) => {
      if (/Firefox/.test(navigator.userAgent)) {
        console.error(
          `To reduce storage access error messages during development`,
          `in Firefox, uncheck the setting "Delete cookies and site data when Firefox`,
          `is closed" in your preferences.`
        );
      }
      console.error(err);
    });
}

ReactDOM.render(
  <React.StrictMode>
    <AppStateProvider>
      {/* https://material-ui.com/styles/advanced/#injectfirst :
     used to be able to override material-ui theme with css classes */}
      <StylesProvider injectFirst>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <App />
          </ThemeProvider>
        </StyledEngineProvider>
      </StylesProvider>
    </AppStateProvider>{' '}
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
