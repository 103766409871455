import { Avatar, AvatarGroup, Box, CardContent, Collapse, Grid, Stack, Tooltip, Typography } from '@mui/material';
import { trim } from 'lodash';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { capitalize, stringToColor } from '../../../helpers/format';
import useUserRoles from '../../../hooks/useUserRoles';
import OrderMenu from '../OrderMenu/OrderMenu';
import { OrderCardProps } from './OrderCard';
import useOrderCardService from './useOrderCardService';
import { useSelector } from 'store/hooks';
import ProtectedLink from 'components/ProtectedLink';
import useManagementRights from 'hooks/rights/useManagementRights';
import OrderKpi from '../OrderKpi/OrderKpi';
import ToggleButton from 'components/ToggleButton';
import { OrderType } from 'types/enums/OrderType';

const MaterialOrderProductionCardContent: React.FC<OrderCardProps> = ({ order, menu = true, kpi = true }) => {
  const [t] = useTranslation();
  const { app } = useSelector((state) => state);
  const pathname = `/${app.customer?.slug}/deliverables/${order.id}`;
  const { canShowKpiOrder } = useManagementRights(order);
  const [isKpiOpen, setIsKpiOpen] = useState(false);
  const userRoles = useUserRoles();
  const { engagementDate, orderConsultantTooltipTitle, orderClient } = useOrderCardService(order);

  return (
    <>
      <CardContent sx={{ position: 'relative' }}>
        <Grid
          item
          direction="row"
          xs={12}
          md={6}
          lg={6}
          sx={{ display: 'flex', paddingBottom: '8px' }}
          justifyContent="space-between"
        >
          <Grid item xs={12} md={6} lg={6}>
            <ProtectedLink pathname={pathname}>
              <Tooltip title={trim([order.name_spec, order.ref_spec].join(' - ')) ?? t<string>('Not_defined')}>
                <div>
                  <Typography noWrap sx={{ color: 'primary.main', fontWeight: 500 }}>
                    ID {order?.id} {order?.name_spec}
                  </Typography>
                </div>
              </Tooltip>
            </ProtectedLink>
            <Typography>{t('Project_name')}</Typography>
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <Stack spacing={2} direction="row">
              {menu && <OrderMenu order={order} />}
            </Stack>
          </Grid>
        </Grid>
        <Grid container spacing={1} justifyContent="space-between">
          <Grid item xs={12} md={12}>
            <Grid container spacing={1} justifyContent="space-between" alignItems="center">
              <Grid item xs={12} md={6} lg={!userRoles.isConsultant && !userRoles.isCustomer ? 1.5 : 2}>
                {order.affair ? (
                  <Tooltip title={trim([order.affair?.otp, order.affair?.name].join(' - '))}>
                    <div>
                      <Typography noWrap sx={{ color: 'primary.main', fontWeight: 500 }}>
                        {order.affair?.name}
                      </Typography>
                      <Typography noWrap sx={{ color: 'primary.main', fontWeight: 500 }}>
                        {order.affair?.otp}
                      </Typography>
                    </div>
                  </Tooltip>
                ) : (
                  <div>
                    <Typography noWrap>{t('Affair_not_indicated')} </Typography>
                    <Box sx={{ height: '1.6rem' }} />
                  </div>
                )}
              </Grid>
              <Grid item xs={12} md={6} lg={!userRoles.isConsultant && !userRoles.isCustomer ? 1.5 : 2}>
                <Tooltip title={orderClient()}>
                  <Typography noWrap sx={{ color: 'primary.main', fontWeight: 500 }}>
                    {orderClient()}
                  </Typography>
                </Tooltip>
                <Typography noWrap>{t('Client')}</Typography>
              </Grid>
              {!userRoles.isConsultant && !userRoles.isCustomer && (
                <Grid item xs={12} md={6} lg={2}>
                  <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                    <Tooltip placement="bottom-start" title={orderConsultantTooltipTitle}>
                      {order.consultants && order.consultants[0] ? (
                        <AvatarGroup max={3}>
                          {order.consultants.map((c) =>
                            c.avatar_uri ? (
                              <Avatar src={c.avatar_uri} key={c.id} />
                            ) : (
                              <Avatar
                                sx={{
                                  backgroundColor: stringToColor(c.first_name),
                                  color: stringToColor(c.first_name, 'color'),
                                  width: 30,
                                  height: 30,
                                }}
                                key={c.id}
                              >{`${capitalize(c.first_name.charAt(0))}${capitalize(c.last_name.charAt(0))}`}</Avatar>
                            )
                          )}
                        </AvatarGroup>
                      ) : (
                        <div>
                          <Typography sx={{ color: 'primary.main', fontWeight: 500 }}>-</Typography>
                        </div>
                      )}
                    </Tooltip>
                    <Typography>{t('Consultants')}</Typography>
                    <Box sx={{ height: '0.4rem' }} />
                  </Box>
                </Grid>
              )}
              <Grid item xs={12} md={6} lg={2}>
                <Tooltip title={engagementDate()}>
                  <Typography noWrap sx={{ color: 'primary.main', fontWeight: 500 }}>
                    {engagementDate()}
                  </Typography>
                </Tooltip>
                <Typography>{t('Engagement_date')}</Typography>
              </Grid>
              <Grid item xs={12} md={6} lg={1.5}>
                <Tooltip title={order.created_at.split('T')[0]}>
                  <Typography noWrap sx={{ color: 'primary.main', fontWeight: 500 }}>
                    {order?.created_at.split('T')[0]}
                  </Typography>
                </Tooltip>
                <Typography>{t('Created on')}</Typography>
              </Grid>
              <Grid item xs={12} md={6} lg={1.5}>
                <Tooltip title={order.updated_at.split('T')[0]}>
                  <Typography noWrap sx={{ color: 'primary.main', fontWeight: 500 }}>
                    {order.updated_at.split('T')[0]}
                  </Typography>
                </Tooltip>
                <Typography>{t('Modified on')}</Typography>
              </Grid>
              <Grid item xs={12} md={6} lg={1} sx={{ display: 'flex', justifyContent: 'right', paddingRight: '8px' }}>
                <Stack spacing={2} direction="row">
                  {kpi &&
                    (canShowKpiOrder ||
                      ((userRoles.isClient || userRoles.isConsultant) &&
                        order.order_type_id === OrderType.TIMEANDMATERIAL)) && (
                      <ToggleButton isOpen={isKpiOpen} handleClick={() => setIsKpiOpen(!isKpiOpen)} />
                    )}
                </Stack>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
      {kpi &&
        (canShowKpiOrder ||
          ((userRoles.isClient || userRoles.isConsultant) && order.order_type_id === OrderType.TIMEANDMATERIAL)) && (
          <Collapse in={isKpiOpen} timeout={500} unmountOnExit>
            <CardContent sx={{ p: 0 }}>
              <OrderKpi order={order} />
            </CardContent>
          </Collapse>
        )}
    </>
  );
};

export default MaterialOrderProductionCardContent;
